<template>
  <nuxt-link :to="props.href" :target="props.target">
    <slot></slot>
  </nuxt-link>
</template>
<script setup>

const props = defineProps({
  href: String,
  target: String
});

</script>